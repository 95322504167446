import { PUBLIC_SENTRY_DSN } from "$env/static/public";
import * as Sentry from "@sentry/svelte";
import type { HandleClientError } from '@sveltejs/kit';
import { v4 as uuidv4 } from 'uuid';

Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    allowUrls: ["trade.polynomial.finance", "polynomial.trade"],
    ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'User rejected the request',
        'Permission for notifications was denied'
    ]
});

export const handleError = (({ error, event }: { error: any; event: any }) => {
    const errorId = uuidv4();
    Sentry.captureException(error, { contexts: { sveltekit: { event, errorId } } });

    return {
        message: error.message,
        errorId
    };
}) satisfies HandleClientError;