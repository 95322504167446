import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [2];

export const dictionary = {
		"/": [6],
		"/faq": [33],
		"/(trading)/share-[id]": [~22,[2]],
		"/(trading)/trade": [23,[2]],
		"/(trading)/[[lang]]/[chain=chain_name]/points": [7,[2,3]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio": [8,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/accounts": [9,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/activities": [10,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/deposits-withdrawals": [11,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/margin-health": [13,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/margin": [12,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/markets": [14,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/open-orders": [15,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/position-history": [16,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/positions": [17,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/trade-history": [18,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/portfolio/wallet": [19,[2,3,4]],
		"/(trading)/[[lang]]/[chain=chain_name]/trade/[market=market_name]": [20,[2,3]],
		"/(trading)/[[lang]]/[chain=chain_name]/trade/[market=market_name]/charts-mobile": [21,[2,3]],
		"/[environment]/earn": [24,[5]],
		"/[environment]/earn/about": [26,[5]],
		"/[environment]/earn/bridge": [27,[5]],
		"/[environment]/earn/invite-codes": [28,[5]],
		"/[environment]/earn/leaderboard": [29,[5]],
		"/[environment]/earn/missions": [30,[5]],
		"/[environment]/earn/onboarding": [31,[5]],
		"/[environment]/earn/stake": [32,[5]],
		"/[environment]/earn/[inviteCode]": [25,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';